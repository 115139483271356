<template>
    <div class="content pt-0">
        <section id="section-booking" class="section booking customCheckout">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 offset-md-3">
                        <h1 class="text-center">
                            Elfelejtett jelszó
                        </h1>
                        <p class="text-center">
                            Amennyiben nem emlékszel jelszavadra, add meg E-mail
                            címedet és visszaállítjuk jelszavadat.
                        </p>
                        <form v-on:submit.prevent="doReset">
                            <div class="my-4">
                                <label>Email cím</label>
                                <input
                                    type="email"
                                    v-model="model.email"
                                    name="email"
                                    class="form-control form-underlined"
                                />
                            </div>

                            <button
                                type="submit"
                                class="bt-orange add-to-cart-btn w-100"
                            >
                                Jelszó visszaállítása
                            </button>

                            <div class="row my-4">
                                <div class="col-md-6">
                                    <router-link
                                        :to="{ name: 'Registration' }"
                                        style="color: white"
                                        >Vissza a regisztrációhoz</router-link
                                    >
                                </div>
                                <div
                                    class="col-md-6 text-end"
                                    style="text-align: right; color: white"
                                >
                                    <router-link
                                        :to="{ name: 'Login' }"
                                        style="color: white"
                                        >Bejelentkezés</router-link
                                    >
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "ForgetPassword",
    data() {
        return {
            model: {
                email: null
            }
        };
    },
    computed: {
        url: function() {
            return process.env.NODE_ENV === "production"
                ? `${process.env.VUE_APP_API_URL}/forgot-password`
                : "/forgot-password";
        }
    },
    methods: {
        doReset() {
            ApiService.post(this.url, this.model).then(() => {
                this.model = {
                    email: null
                };

                self.toastMessage(
                    self.$translate.t("Success!"),
                    self.$translate.t("We've sent the instructions in email.")
                );
            });
        }
    }
};
</script>

<style scoped></style>
